import { navigate } from "gatsby"
import React from "react"
import Video from "../Video/Video"
import "./Archive.css"

const ArchiveProfile = ({ pageContext }) => {
  const { profileId, image, full_name, color, archive_video_link } = pageContext

  const params = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )

  return (
    <div className="archive-wrap">
      <Video
        profileId={profileId}
        name={full_name.text}
        color={color}
        onClose={() => {
          if (params.get("search")) {
            navigate(`/archive?search=${params.get("search")}`)
          } else {
            navigate("/archive")
          }
        }}
        image={image}
        isArchive
        video_link={archive_video_link.url}
      />
    </div>
  )
}

export default ArchiveProfile
